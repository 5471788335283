<template>
  <div class="CEESAR-DialogHost">
      <div :class=" ['CEESAR-DialogHostMask',
                    isDialogShown? 'CEESAR-DialogHostMask-Visible': 'CEESAR-DialogHostMask-Hidden']" />
      <div :class=" ['CEESAR-DialogHostContent',
                    isDialogShown? 'CEESAR-DialogHostContent-Visible': 'CEESAR-DialogHostContent-Hidden']" 
            id="DialogHostContainer"/>
  </div>
</template>

<script>
export default {
    name: "CEESAR-DialogHost",
    data(){
        return{
            isDialogShown: false,

            dialogNode: undefined,
            dialogParentNode: undefined
        };
    },
    created(){
        this.$ceesar.design.registerDialogEvent("Show", this.ShowDialog);
        this.$ceesar.design.registerDialogEvent("Close", this.CloseDialog);
    },
    beforeDestroy(){
        this.$ceesar.design.unregisterDialogEvent("Show");
        this.$ceesar.design.unregisterDialogEvent("Close");
    },
    methods:{
        ShowDialog(pId){
            
            let eDialogHost = document.getElementById("DialogHostContainer");

            this.dialogNode = document.getElementById(pId);
            this.dialogParentNode = this.dialogNode.parentNode;

            eDialogHost.innerHTML = '';
            eDialogHost.appendChild(this.dialogNode);

            this.isDialogShown = true;
        },
        CloseDialog(){
            this.isDialogShown = false;

            window.setTimeout(
                () => {
                    this.dialogParentNode.appendChild(this.dialogNode);        
                }, 1000
            );
        }
    }
}
</script>

<style>

</style>